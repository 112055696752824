import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useIntl } from "gatsby-plugin-intl";

import QrSection from '../../components/QrSection';
import QrSectionTitle from '../../components/QrSectionTitle';
import QrPost from '../../components/QrPost';

const Press = (props) => {
    const intl = useIntl();

    return (
        <div id="press">
            <QrSection id="qr-press" gutters={true}>
                <Container>
                    <QrSectionTitle
                        section={intl.formatMessage({ id: "PRESS.SECTION" })}
                        title={intl.formatMessage({ id: "PRESS.TITLE" })}
                        description={intl.formatMessage({ id: "PRESS.DESCRIPTION" })}
                        className="mb-3"
                    />
                    <Row>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                        <Col xs={12} md={6} lg={3} className="mb-3">
                            <QrPost type={1} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="text-center">
                            <Button href="#" variant="outline-primary">Ver todos</Button>
                        </Col>
                    </Row>
                </Container>
            </QrSection>
        </div>
    )
}

export default Press;