import React from 'react';
import { Row, Col } from 'react-bootstrap';

const QrSectionTitle = (props) => {
    const { section, title, description, className } = props;

    return (
        <Row className={`qr-section-title ${className ? className : ""}`}>
            <Col>
                <h2>
                    {section}
                </h2>
                <h3>
                    {title}
                </h3>
                <p>
                    {description}
                </p>
            </Col>
        </Row>
    )
}

export default QrSectionTitle;